import React, {useEffect} from 'react';
import {Button, Modal, Nav, Tab} from '@themesberg/react-bootstrap';
import {FormattedMessage} from 'react-intl';
import BookingShareModal from './BookingShareModal';
import BookingConfirmModal from './BookingConfirmModal';
import BookingCreateModal from './BookingCreateModal';

export default (props) => {
  const {intl, lengthUnit, booking, show, onClose, onRefresh} = props;
  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [showShareModal, setShowShareModal] = React.useState(false);
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const formatter = new Intl.NumberFormat(navigator.language, {style: 'currency', currency: booking ? booking.currency : 'EUR'});

  useEffect(() => {
    setShowDetailsModal(show);
  }, [show]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setShowDetailsModal(false);
  };

  return (
    <>
      <Modal as={Modal.Dialog} centered show={showDetailsModal} onHide={() => handleClose()}>
        <Modal.Header>
          <Modal.Title className="h6"><FormattedMessage id="BOOKING_DETAILS"/></Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => handleClose()}/>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container defaultActiveKey="journey">
            <Nav fill variant="pills" className="flex-column flex-sm-row">
              <Nav.Item style={{marginLeft: '10px'}}>
                <Nav.Link eventKey="journey" className="mb-sm-3 mb-md-0">
                  <FormattedMessage id="JOURNEY"/>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item style={{marginRight: booking && booking.fields && booking.fields.length > 0 ? '0px' : '10px'}}>
                <Nav.Link eventKey="customer" className="mb-sm-3 mb-md-0">
                  <FormattedMessage id="CUSTOMER"/>
                </Nav.Link>
              </Nav.Item>
              {booking && booking.fields && booking.fields.length > 0 &&
                <Nav.Item style={{marginRight: '10px'}}>
                  <Nav.Link eventKey="others" className="mb-sm-3 mb-md-0">
                    Autres
                  </Nav.Link>
                </Nav.Item>
              }
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="journey" className="py-4">
                {booking && <div>
                  <b><FormattedMessage id="STARTING_ADDRESS"/>:</b> {booking.startingAddress}<br/>
                  <b><FormattedMessage id="ARRIVAL_ADDRESS"/>:</b> {booking.arrivalAddress}<br/>
                  <b><FormattedMessage id="VEHICLE"/>:</b> {booking.vehicle}<br/>
                  <b><FormattedMessage id="PASSENGERS"/>:</b> {booking.passengers}<br/>
                  <b><FormattedMessage id="BAGGAGE"/>:</b> {booking.baggage}<br/>
                  <b><FormattedMessage
                    id="ONE_WAY_OR_ROUND_TRIP"/>:</b> {booking.returnPickUpTime ? intl.formatMessage({id: 'ROUND_TRIP'}) : intl.formatMessage({id: 'ONE_WAY'})}<br/>
                  <b>
                    {!booking.returnPickUpTime &&
                      <FormattedMessage id="DATES_AND_TIMES"/>
                    }
                    {booking.returnPickUpTime &&
                      <FormattedMessage id="ONE_WAY_DATES_AND_TIMES"/>
                    }
                    :</b> {booking.pickUpTime}<br/>
                  {booking.returnPickUpTime &&
                    <span><b><FormattedMessage id="RETURN_DATES_AND_TIMES"/>:</b> {booking.returnPickUpTime}<br/></span>
                  }
                  <b><FormattedMessage id="TRAVEL_TIME"/>:</b> {booking.durationMinutes} minutes<br/>
                  <b><FormattedMessage id="DISTANCE"/>:</b> {booking.distanceKm} km ({booking.distanceMiles} mi)<br/>
                  {booking.travelNumber && <span><b><FormattedMessage id="FLIGHT_OR_TRAIN_NUMBER"/>:</b> {booking.travelNumber}<br/></span>}
                  {booking.notes && <span><b><FormattedMessage id="ORDER_NOTES"/>:</b> {booking.notes}<br/></span>}
                  {booking.options && booking.options.map(o => <span><b>{o.name}:</b> {o.quantity}<br/></span>)}
                  <br/>
                  <h3 className="text-center mb-0" style={booking.discount ? {
                    textDecoration: 'line-through',
                    textDecorationThickness: '2px',
                    color: '#4A5073',
                    opacity: 0.75
                  } : {color: '#4A5073'}}>{formatter.format(booking.price)}</h3>
                  {booking.discount && <h3 className="text-center mb-0" style={{color: '#4A5073'}}>{formatter.format(booking.discount.discountPrice)}</h3>}
                  {booking.discount && <p className="text-center mb-0">
                    <FormattedMessage id="DISCOUNT_CODE_USED"/>: <b>{booking.discount.code}</b> (
                    {booking.discount.percentageDiscount && <span>{booking.discount.percentageDiscount} %</span>}
                    {(booking.discount.percentageDiscount && booking.discount.fixedDiscount) && <span> - </span>}
                    {booking.discount.fixedDiscount && <span>{formatter.format(booking.discount.fixedDiscount)}</span>})</p>}
                </div>}
              </Tab.Pane>
              <Tab.Pane eventKey="customer" className="py-4">
                {booking && <div>
                  {booking.customer.firstName && <span><b><FormattedMessage id="FIRST_NAME"/>:</b> {booking.customer.firstName}<br/></span>}
                  {booking.customer.lastName && <span><b><FormattedMessage id="LAST_NAME"/>:</b> {booking.customer.lastName}<br/></span>}
                  {booking.customer.companyName && <span><b><FormattedMessage id="COMPANY_NAME"/>:</b> {booking.customer.companyName}<br/></span>}
                  {booking.customer.email &&
                    <span><b><FormattedMessage id="EMAIL_ADDRESS"/>:</b> <a href={'mailto:' + booking.customer.email}>{booking.customer.email}</a><br/></span>}
                  {booking.customer.phone && <span><b><FormattedMessage id="PHONE"/>:</b> <a href={'tel:' + booking.customer.phone}>{booking.customer.phone}</a><br/></span>}
                  {booking.customer.address && <span><b><FormattedMessage id="ADDRESS"/>:</b> {booking.customer.address}<br/></span>}
                </div>}
              </Tab.Pane>
              <Tab.Pane eventKey="others" className="py-4">
                {booking && booking.fields && booking.fields.length > 0 && <div>
                  {booking.fields.map(field => <span><b>{field.name}:</b> {field.value}<br/></span>)}
                </div>}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          {booking && booking.status === 'VALIDATED' &&
            <Button variant="success" onClick={() => {
              handleClose();
              setShowConfirmModal(true);
            }
            }>
              <FormattedMessage id="CONFIRM_BOOKING"/>
            </Button>
          }
          {booking && booking.createdManually === true &&
            <Button variant="light" onClick={() => {
              handleClose();
              setShowCreateModal(true);
            }
            }>
              <FormattedMessage id="EDIT"/>
            </Button>
          }
          <Button variant="info" className="ms-auto" onClick={() => {
            handleClose();
            setShowShareModal(true);
          }}>
            <FormattedMessage id="SHARE"/>
          </Button>
        </Modal.Footer>
      </Modal>
      <BookingConfirmModal intl={intl} bookingId={booking?.id} show={showConfirmModal} onClose={() => setShowConfirmModal(false)} onRefresh={onRefresh}></BookingConfirmModal>
      <BookingShareModal intl={intl} bookingId={booking?.id} show={showShareModal} onClose={() => setShowShareModal(false)}></BookingShareModal>
      {showCreateModal && <BookingCreateModal intl={intl} lengthUnit={lengthUnit} show={showCreateModal} onClose={() => setShowCreateModal(false)} onRefresh={onRefresh} booking={booking} edit></BookingCreateModal>}
    </>
  );
}