import {ACCESS_TOKEN, API_URL} from './constants';
import axios from 'axios';
import Utils from './Utils';

const getConfig = () => {
  return {
    headers: {
      'Authorization': localStorage.getItem(ACCESS_TOKEN) ? 'Bearer ' + localStorage.getItem(ACCESS_TOKEN) : ''
    }
  }
}

const getBlobConfig = () => {
  return {
    headers: {
      'Authorization': localStorage.getItem(ACCESS_TOKEN) ? 'Bearer ' + localStorage.getItem(ACCESS_TOKEN) : ''
    },
    responseType: 'blob'
  }
}

const getTextConfig = () => {
  return {
    headers: {
      'Authorization': localStorage.getItem(ACCESS_TOKEN) ? 'Bearer ' + localStorage.getItem(ACCESS_TOKEN) : '',
      'Content-Type': 'text/plain'
    }
  }
}

const getMultipartConfig = () => {
  return {
    headers: {
      'Authorization': localStorage.getItem(ACCESS_TOKEN) ? 'Bearer ' + localStorage.getItem(ACCESS_TOKEN) : '',
      'Content-Type': 'multipart/form-data'
    }
  }
}

const getJsonConfig = () => {
  return {
    headers: {
      'Content-Type': 'application/json'
    }
  }
}

export default {
  postSignIn: async (email, password, rememberMe) => {
    return await axios.post(API_URL + '/auth/signin',
      {
        email: email,
        password: password,
        rememberMe: rememberMe
      });
  },
  getResetPassword: async (email) => {
    try {
      return await axios.get(API_URL + '/auth/reset-password/' + email);
    } catch (error) {
    }
  },
  postChangePassword: async (password, token) => {
    return await axios.post(API_URL + '/auth/change-password',
      {
        password: password,
        token: token
      });
  },
  postNewProfile: async (history, data) => {
    return await axios.post(API_URL + '/admin/new-profile', data, getConfig());
  },
  postProfile: async (history, data) => {
    return await axios.post(API_URL + '/admin/profile', data, getConfig());
  },
  getStripeUrl: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/stripe-url', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getStripePortalUrl: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/stripe-portal', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getStripeRenewSubscriptionUrl: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/renew-subscription', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getStripeInfos: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/stripe-infos', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getStripeDisconnect: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/stripe-disconnect', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getSettings: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/settings', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postSettings: async (history, data) => {
    try {
      return await axios.post(API_URL + '/admin/settings', data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postImage: async (history, data) => {
    try {
      return await axios.post(API_URL + '/admin/settings/image', data, getMultipartConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  deleteImage: async (history) => {
    try {
      return await axios.delete(API_URL + '/admin/settings/image', getMultipartConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getVehicles: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/vehicles', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postVehicles: async (history, data) => {
    try {
      return await axios.post(API_URL + '/admin/vehicles', data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  putVehicles: async (history, data, id) => {
    try {
      return await axios.put(API_URL + '/admin/vehicles/' + id, data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  deleteVehicles: async (history, id) => {
    try {
      return await axios.delete(API_URL + '/admin/vehicles/' + id, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getDisableVehicle: async (history, id) => {
    try {
      return await axios.get(API_URL + '/admin/vehicles/' + id + '/disable', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getEnableVehicle: async (history, id) => {
    try {
      return await axios.get(API_URL + '/admin/vehicles/' + id + '/enable', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postReorderVehicles: async (history, data) => {
    try {
      return await axios.post(API_URL + '/admin/vehicles/reorder', data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getSurcharges: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/surcharges', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postSurcharges: async (history, data) => {
    try {
      return await axios.post(API_URL + '/admin/surcharges', data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  putSurcharges: async (history, data, id) => {
    try {
      return await axios.put(API_URL + '/admin/surcharges/' + id, data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  deleteSurcharges: async (history, id) => {
    try {
      return await axios.delete(API_URL + '/admin/surcharges/' + id, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getDisableSurcharge: async (history, id) => {
    try {
      return await axios.get(API_URL + '/admin/surcharges/' + id + '/disable', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getEnableSurcharge: async (history, id) => {
    try {
      return await axios.get(API_URL + '/admin/surcharges/' + id + '/enable', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getPackages: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/packages', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postPackages: async (history, data) => {
    try {
      return await axios.post(API_URL + '/admin/packages', data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  putPackages: async (history, data, id) => {
    try {
      return await axios.put(API_URL + '/admin/packages/' + id, data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  deletePackages: async (history, id) => {
    try {
      return await axios.delete(API_URL + '/admin/packages/' + id, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getDisablePackage: async (history, id) => {
    try {
      return await axios.get(API_URL + '/admin/packages/' + id + '/disable', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getEnablePackage: async (history, id) => {
    try {
      return await axios.get(API_URL + '/admin/packages/' + id + '/enable', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getCopyPackage: async (history, id) => {
    try {
      return await axios.get(API_URL + '/admin/packages/' + id + '/copy', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postReorderPackages: async (history, data) => {
    try {
      return await axios.post(API_URL + '/admin/packages/reorder', data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getAdminBookings: async (history, page) => {
    try {
      return await axios.get(API_URL + '/admin/bookings?page=' + page, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getAdminBooking: async (history, id) => {
    try {
      return await axios.get(API_URL + '/admin/bookings/' + id, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postAdminBooking: async (history, data) => {
    try {
      return await axios.post(API_URL + '/admin/bookings', data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  putAdminBooking: async (history, data, id) => {
    try {
      return await axios.put(API_URL + '/admin/bookings/' + id, data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getBookingCsvReport: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/bookings/csv', getBlobConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getBookingPreview: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/bookings/preview', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postConfirmAdminBooking: async (history, id, data) => {
    try {
      return await axios.post(API_URL + '/admin/bookings/' + id + '/confirm', data, getTextConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postCancelAdminBooking: async (history, id, data) => {
    try {
      return await axios.post(API_URL + '/admin/bookings/' + id + '/cancel', data, getTextConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postShareAdminBooking: async (history, id, data) => {
    try {
      return await axios.post(API_URL + '/admin/bookings/' + id + '/share', data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  deleteAdminBooking: async (history, id) => {
    try {
      return await axios.delete(API_URL + '/admin/bookings/' + id, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getModule: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/module', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postModule: async (history, data) => {
    try {
      delete data['identifier'];
      return await axios.post(API_URL + '/admin/module', data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getCheckSubscription: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/check-subscription', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getBooking: async (id) => {
    try {
      return await axios.get(API_URL + '/booking/' + id, getConfig());
    } catch (error) {
    }
  },
  getBookingUrl: async (id) => {
    try {
      return await axios.get(API_URL + '/booking/' + id + '/url', getConfig());
    } catch (error) {
    }
  },
  getConfirmBooking: async (id, token) => {
    try {
      return await axios.get(API_URL + '/booking/' + id + '/confirm/' + token, getConfig());
    } catch (error) {
    }
  },
  getCancelBooking: async (id, token) => {
    try {
      return await axios.get(API_URL + '/booking/' + id + '/cancel/' + token, getConfig());
    } catch (error) {
    }
  },
  postCheckout: async (data, id) => {
    try {
      return await axios.post(API_URL + '/booking/' + id + '/checkout', data, getConfig());
    } catch (error) {
    }
  },
  getMonthlySubscriptionUrl: async (id) => {
    try {
      return await axios.get(API_URL + '/subscription/checkout-monthly', getConfig());
    } catch (error) {
    }
  },
  getAnnualSubscriptionUrl: async (id) => {
    try {
      return await axios.get(API_URL + '/subscription/checkout-annual', getConfig());
    } catch (error) {
    }
  },
  getLifetimeSubscriptionUrl: async (id) => {
    try {
      return await axios.get(API_URL + '/subscription/checkout-lifetime', getConfig());
    } catch (error) {
    }
  },
  getSignUp: async (sessionId) => {
    try {
      return await axios.get(API_URL + '/auth/signup/' + sessionId, getConfig());
    } catch (error) {
    }
  },
  postContactUs: async (history, data) => {
    try {
      return await axios.post(API_URL + '/admin/contact/', data, getTextConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getNominatimSearchCity: async (city) => {
    return await axios.get('https://nominatim.openstreetmap.org/search.php?format=jsonv2&city=' + city);
  },
  getCurrency: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/currency', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getLanguage: async (history, lang) => {
    try {
      return await axios.get(API_URL + '/admin/language?lang=' + lang, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getIcons: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/icons', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getOptions: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/options', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postOptions: async (history, data) => {
    try {
      return await axios.post(API_URL + '/admin/options', data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  putOptions: async (history, data, id) => {
    try {
      return await axios.put(API_URL + '/admin/options/' + id, data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  deleteOptions: async (history, id) => {
    try {
      return await axios.delete(API_URL + '/admin/options/' + id, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getDisableOption: async (history, id) => {
    try {
      return await axios.get(API_URL + '/admin/options/' + id + '/disable', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getEnableOption: async (history, id) => {
    try {
      return await axios.get(API_URL + '/admin/options/' + id + '/enable', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getCopyOption: async (history, id) => {
    try {
      return await axios.get(API_URL + '/admin/options/' + id + '/copy', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postReorderOptions: async (history, data) => {
    try {
      return await axios.post(API_URL + '/admin/options/reorder', data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getDiscountCodes: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/discount', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postDiscountCodes: async (history, data) => {
    try {
      return await axios.post(API_URL + '/admin/discount', data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  putDiscountCodes: async (history, data, id) => {
    try {
      return await axios.put(API_URL + '/admin/discount/' + id, data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  deleteDiscountCodes: async (history, id) => {
    try {
      return await axios.delete(API_URL + '/admin/discount/' + id, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getDisableDiscountCode: async (history, id) => {
    try {
      return await axios.get(API_URL + '/admin/discount/' + id + '/disable', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getEnableDiscountCode: async (history, id) => {
    try {
      return await axios.get(API_URL + '/admin/discount/' + id + '/enable', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getDiscount: async (id, code) => {
    try {
      return await axios.get(API_URL + '/booking/' + id + '/discount/' + code, getConfig());
    } catch (error) {
    }
  },
  getModuleIdentifierByName: async (name) => {
    try {
      return await axios.get(API_URL + '/module/name/' + name, getConfig());
    } catch (error) {
    }
  },
  getBookingFields: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/fields', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postBookingFields: async (history, data) => {
    try {
      return await axios.post(API_URL + '/admin/fields', data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  putFields: async (history, data, id) => {
    try {
      return await axios.put(API_URL + '/admin/fields/' + id, data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  deleteFields: async (history, id) => {
    try {
      return await axios.delete(API_URL + '/admin/fields/' + id, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getDisableField: async (history, id) => {
    try {
      return await axios.get(API_URL + '/admin/fields/' + id + '/disable', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getEnableField: async (history, id) => {
    try {
      return await axios.get(API_URL + '/admin/fields/' + id + '/enable', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postReorderFields: async (history, data) => {
    try {
      return await axios.post(API_URL + '/admin/fields/reorder', data, getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getPlanning: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/planning', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  getModuleIdentifier: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/module/identifier', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
  postEstimatePrice: async (identifier, data) => {
    return await axios.post(API_URL + `/module/${identifier}/estimate-price`, data, getJsonConfig());
  },
  getCustomers: async (history) => {
    try {
      return await axios.get(API_URL + '/admin/customers', getConfig());
    } catch (error) {
      if (error.response.status === 401) {
        Utils.logout(history);
      }
    }
  },
};