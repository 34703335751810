import React, {useEffect} from 'react';
import {Button, Form, Modal} from '@themesberg/react-bootstrap';
import {FormattedMessage} from 'react-intl';
import API from '../services/API';
import Utils from '../services/Utils';
import {useHistory} from 'react-router-dom';

export default (props) => {
  const {intl, bookingId, show, onClose, onRefresh} = props;
  const history = useHistory();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState('');

  useEffect(() => {
    setShowConfirmModal(show);
  }, [show]);

  const handleClose = () => {
    setConfirmMessage('');
    if (onClose) {
      onClose();
    }
    setShowConfirmModal(false);
  };

  const handleConfirmBooking = () => {
    API.postConfirmAdminBooking(history, bookingId, confirmMessage ? confirmMessage : ' ').then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'BOOKING_CONFIRMED'}), intl.formatMessage({id: 'CUSTOMER_NOTIFIED_BY_EMAIL'}));
        handleClose();
        onRefresh();
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  return (
    <Modal as={Modal.Dialog} centered show={showConfirmModal} onHide={() => handleClose()}>
      <Modal.Header>
        <Modal.Title className="h6"><FormattedMessage id="CONFIRM_BOOKING"/></Modal.Title>
        <Button variant="close" aria-label="Close" onClick={() => handleClose()}/>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage id="YOU_CAN_ATTACH_MSG_TO_CUSTOMER"/>
        <br/>
        <br/>
        <Form.Control as="textarea" rows="3" id="confirmMessage"
                      onChange={(e) => setConfirmMessage(e.target.value)} value={confirmMessage}/>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleConfirmBooking}>
          <FormattedMessage id="CONFIRM_BOOKING"/>
        </Button>
        <Button variant="link" className="text-gray ms-auto" onClick={() => handleClose()}>
          <FormattedMessage id="CLOSE"/>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}