import axios from 'axios';
import {MAPBOX, TOMTOM} from './constants';

export default {
  search: async (query, limit) => {
    return await axios.get('https://api.tomtom.com/search/2/search/' + query + '.json', {
      params: {
        key: TOMTOM,
        limit: limit,
      },
    });
  },
  route: async (startingLng, startingLat, arrivalLng, arrivalLat) => {
    return await axios.get('https://api.mapbox.com/directions/v5/mapbox/driving/' + startingLng + '%2C' + startingLat + '%3B' + arrivalLng + '%2C' + arrivalLat, {
      params: {
        alternatives: false,
        geometries: 'geojson',
        overview: 'simplified',
        steps: false,
        access_token: MAPBOX,
      },
    });
  }
}