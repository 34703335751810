import React, {useEffect, useReducer} from 'react';
import {Button, ButtonGroup, Col, Form, InputGroup, Modal, Nav, Row, Tab} from '@themesberg/react-bootstrap';
import {FormattedMessage} from 'react-intl';
import Select from 'react-select';
import Utils from '../services/Utils';
import {DAYS_BORDER_COLOR} from '../services/constants';
import Datetime from 'react-datetime';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import CreatableSelect from 'react-select/creatable';
import Countries from './Countries';
import API from '../services/API';
import Geocoding from '../services/Geocoding';
import {useHistory} from 'react-router-dom';

export default (props) => {
  const formReducer = (state, event) => {
    if (event.type === 'RESET') {
      return {
        route: 'ONE_WAY'
      };
    } else if (event.name && event.value !== undefined) {
      return {
        ...state,
        [event.name]: event.value
      };
    } else if (typeof event === 'object' && !event.name) {
      return {
        ...state,
        ...event
      };
    } else {
      return state;
    }
  };

  const {intl, lengthUnit = 'KM', show, onClose, onRefresh, booking, edit} = props;
  const history = useHistory();
  const [showModal, setShowModal] = React.useState(false);
  const [vehicles, setVehicles] = React.useState([]);
  const [formData, setFormData] = useReducer(formReducer, {route: 'ONE_WAY'});
  const [currency, setCurrency] = React.useState();
  const [moduleIdentifier, setModuleIdentifier] = React.useState();
  const [byPassEstimate, setByPassEstimate] = React.useState(edit);
  const [byPassGeocoding, setByPassGeocoding] = React.useState(edit);
  const DATE_FORMAT = 'YYYY/MM/DD HH:mm';
  const FR_DATE_FORMAT = 'DD/MM/YYYY HH:mm';

  // Address fields
  const selectRef = React.useRef(null);
  const selectRefReturn = React.useRef(null);
  const [inputValue, setInputValue] = React.useState('');
  const [inputValueReturn, setInputValueReturn] = React.useState('');
  const [addressOptions, setAddressOptions] = React.useState([]);
  const [isGeoLoading, setIsGeoLoading] = React.useState(false);
  const typingTimeout = React.useRef(null);
  const geocodingTimeout = React.useRef(null);
  const estimatePriceTimeout = React.useRef(null);

  // Customers name fields
  const selectRefFirstName = React.useRef(null);
  const selectRefLastName = React.useRef(null);
  const [customersOptions, setCustomersOptions] = React.useState([]);
  const [isSelectingName, setSelectingName] = React.useState(false);

  const getLocaleDateFormat = () => {
    return Utils.getLanguage().toUpperCase() === 'FR' ? FR_DATE_FORMAT : DATE_FORMAT;
  }

  const estimatePrice = (identifier) => {
    if (show && byPassEstimate && formData.startingAddress) {
      setByPassEstimate(false);
    } else if (showModal) {
      if (formData.route === 'ROUND_TRIP' && !formData.returnPickUpTime) {
        return;
      }

      const request = {
        distanceKm: lengthUnit === 'MI' ? (formData.distance * 0.621371).toFixed(2) : formData.distance,
        durationMinutes: formData.duration,
        pickUpTime: moment.utc(formData.pickUpTime, DATE_FORMAT).toDate(),
        returnPickUpTime: (formData.route === 'ROUND_TRIP' && formData.returnPickUpTime) ? moment.utc(formData.returnPickUpTime, DATE_FORMAT).toDate() : '',
        passengers: (formData.passengers ?? 1),
        baggage: (formData.baggage ?? 0),
        vehicleId: formData.vehicleId ?? vehicles[0].id,
        route: formData.route,
        startingLat: formData.startingLat,
        startingLng: formData.startingLng,
        arrivalLat: formData.arrivalLat,
        arrivalLng: formData.arrivalLng,
        lang: Utils.getLanguage(),
        options: []
      };

      API.postEstimatePrice(identifier, request).then(response => {
        if (response && response.data.success === true && response.data.data.price !== 0) {
          setFormData({
            name: 'price',
            value: response.data.data.price.toFixed(2)
          });
        }
      });
    }
  }

  useEffect(() => {
    if (edit && booking) {
      setInputValue(booking.startingAddress);
      setInputValueReturn(booking.arrivalAddress);
      setFormData({
        startingAddress: booking.startingAddress,
        arrivalAddress: booking.arrivalAddress,
        startingLat: booking.startingCoords.split(',')[0],
        startingLng: booking.startingCoords.split(',')[1],
        arrivalLat: booking.arrivalCoords.split(',')[0],
        arrivalLng: booking.arrivalCoords.split(',')[1],
        vehicleId: booking.vehicleId,
        passengers: booking.passengers,
        baggage: booking.baggage,
        route: booking.returnPickUpTime ? 'ROUND_TRIP' : 'ONE_WAY',
        pickUpTime: moment.utc(booking.pickUpTime, FR_DATE_FORMAT).format(DATE_FORMAT),
        returnPickUpTime: booking.returnPickUpTime ? moment.utc(booking.returnPickUpTime, FR_DATE_FORMAT).format(DATE_FORMAT) : '',
        duration: booking.durationMinutes,
        distance: lengthUnit === 'MI' ? booking.distanceMiles : booking.distanceKm,
        price: booking.price,
        notes: booking.notes,
        firstName: booking.customer.firstName,
        lastName: booking.customer.lastName,
        company: booking.customer.companyName,
        email: booking.customer.email,
        phone: booking.customer.phone,
        country: booking.customer.country,
        address: booking.customer.street,
        additionalAddress: booking.customer.additionalAddress,
        city: booking.customer.city,
        postalCode: booking.customer.postalCode
      });
    }
  }, [show]);

  useEffect(() => {
    if (show && edit) {
      setCurrency({
        code: booking.currency
      });
    } else if (!currency) {
      API.getCurrency(history).then(response => {
        if (response && response.data.success === true) {
          setCurrency(response.data.data);
        }
      });
    }
    if (vehicles.length === 0) {
      getVehicles();
    }
    setShowModal(show);
  }, [show]);

  useEffect(() => {
    if (show && byPassGeocoding && formData.startingAddress) {
      setByPassGeocoding(false);
    } else if (show) {
      if (formData.startingAddress && formData.arrivalAddress) {
        if (geocodingTimeout.current) {
          clearTimeout(geocodingTimeout.current);
        }

        geocodingTimeout.current = setTimeout(() => {
          Geocoding.route(formData.startingLng, formData.startingLat, formData.arrivalLng, formData.arrivalLat).then(response => {
            if (response.status === 200) {
              const data = response.data;
              if (data.routes.length > 0) {
                const distanceKm = data.routes[0].distance / 1000;
                const durationMinutes = Math.round(data.routes[0].duration / 60);

                if (lengthUnit === 'MI') {
                  setFormData({
                    name: 'distance',
                    value: (distanceKm * 0.621371).toFixed(2)
                  });
                } else {
                  setFormData({
                    name: 'distance',
                    value: distanceKm.toFixed(2)
                  });
                }

                setFormData({
                  name: 'duration',
                  value: durationMinutes
                });
              }
            }
          });
        }, 1000);
      }
    }
  }, [formData.startingAddress, formData.arrivalAddress]);

  useEffect(() => {
    if (formData.startingAddress && formData.arrivalAddress && formData.pickUpTime && formData.distance) {
      if (estimatePriceTimeout.current) {
        clearTimeout(estimatePriceTimeout.current);
      }

      estimatePriceTimeout.current = setTimeout(() => {
        if (!moduleIdentifier) {
          API.getModuleIdentifier(history).then(response => {
            if (response && response.data.success === true) {
              setModuleIdentifier(response.data.data);
              estimatePrice(response.data.data);
            } else {
              Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
            }
          });
        } else {
          estimatePrice(moduleIdentifier);
        }
      }, 1000);
    }
  }, [formData.pickUpTime, formData.returnPickUpTime, formData.route, formData.passengers, formData.baggage, formData.vehicleId]);

  const getVehicles = () => {
    API.getVehicles(history).then(response => {
      if (response && response.data.success === true) {
        const vehicles = response.data.data.vehicles.sort(function (a, b) {
          return a.position - b.position
        });
        setVehicles(vehicles);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const fetchAddresses = async (query) => {
    if (!query.trim()) return;
    setIsGeoLoading(true);
    try {
      const response = await Geocoding.search(query, 7);
      setIsGeoLoading(false);
      return response.data.results.map((item) => ({
        value: item.id,
        label: item.address.freeformAddress,
        position: item.position
      }));
    } catch (error) {
      setIsGeoLoading(false);
      setAddressOptions([]);
    }
  };

  const refreshSelectFocus = (ref) => {
    if (ref.current) {
      ref.current.blur();
      setTimeout(() => {
        ref.current.focus();
      }, 0);
    }
  };

  const handleClose = () => {
    setFormData({type: 'RESET'});
    if (onClose) {
      onClose();
    }
    setShowModal(false);
  };

  const handleChange = event => {
    setFormData({
      name: event.target.id,
      value: event.target.value
    });
  }

  const handleSelectChange = (name, value) => {
    setFormData({
      name: name,
      value: value.label
    });

    if (name === 'startingAddress') {
      setFormData({
        startingLat: value.position.lat,
        startingLng: value.position.lon,
      });
    } else if (name === 'arrivalAddress') {
      setFormData({
        arrivalLat: value.position.lat,
        arrivalLng: value.position.lon,
      });
    }
  };

  const handleSelectCustomerChange = (ref, select) => {
    setSelectingName(true);
    setTimeout(() => {
      ref.current?.blur();
      setTimeout(() => {
        ref.current?.focus();
        ref.current?.blur();
        setFormData({
          firstName: select.value.firstName ?? (ref === selectRefFirstName ? select.value : formData.firstName),
          lastName: select.value.lastName ?? (ref === selectRefLastName ? select.value : formData.lastName)
        });
      }, 0);
    }, 0);
    setTimeout(() => {
      setFormData({
        firstName: select.value.firstName ?? (ref === selectRefFirstName ? select.value : formData.firstName),
        lastName: select.value.lastName ?? (ref === selectRefLastName ? select.value : formData.lastName),
        company: select.value.companyName ?? '',
        email: select.value.email ?? '',
        phone: select.value.phone ?? '',
        country: select.value.country ?? (intl.locale === 'fr' ? 'FR' : 'US'),
        address: select.value.address ?? '',
        additionalAddress: select.value.addressComplement ?? '',
        city: select.value.city ?? '',
        postalCode: select.value.postalCode ?? ''
      });
      setSelectingName(false);
    }, 0);
  };

  const handleNavSelect = (eventKey) => {
    if (eventKey === 'customer' && !customersOptions.length) {
      API.getCustomers(history).then(response => {
        if (response && response.data.success === true) {
          setCustomersOptions(response.data.data.customers.map((customer) => ({
            value: customer,
            label: `${customer.firstName} ${customer.lastName}`
          })));
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    }
  };

  const handleInputChangeAddress = (ref, value, isReturn) => {
    if (isReturn) {
      setInputValueReturn(value);
    } else {
      setInputValue(value);
    }

    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }

    typingTimeout.current = setTimeout(() => {
      if (value.length > 1) {
        fetchAddresses(value).then(data => {
          setAddressOptions(data);
          refreshSelectFocus(ref);
          if (isReturn) {
            setInputValueReturn(value);
          } else {
            setInputValue(value);
          }
        });
      }
    }, 1000);
  };

  const handleInputChangeName = (name, value, actionMeta) => {
    if (actionMeta.action !== 'input-blur') {
      setFormData({
        name: name,
        value: value
      });
    }
  };

  const handleClickJourney = (e) => {
    if (e.target.id === 'oneWay') {
      setFormData({
        name: 'route',
        value: 'ONE_WAY'
      });
    } else if (e.target.id === 'roundTrip') {
      setFormData({
        name: 'route',
        value: 'ROUND_TRIP'
      });
    }
  }

  const handleChangePickup = value => {
    setFormData({
      name: 'pickUpTime',
      value: moment(value).format(DATE_FORMAT)
    });
  }

  const handleChangeReturnPickup = value => {
    setFormData({
      name: 'returnPickUpTime',
      value: moment(value).format(DATE_FORMAT)
    });
  }

  const handleAddBooking = (e) => {
    e.preventDefault();

    if (!formData.passengers) {
      formData.passengers = 1;
    }
    if (!formData.baggage) {
      formData.baggage = 0;
    }
    if (!formData.vehicleId) {
      formData.vehicleId = vehicles[0].id;
    }

    if (edit) {
      API.putAdminBooking(history, formData, booking.id).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SAVED_CHANGES'}));
          handleClose();
          onRefresh();
          setCustomersOptions([]);
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    } else {
      API.postAdminBooking(history, formData).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'BOOKING_ADDED'}));
          handleClose();
          onRefresh();
          setCustomersOptions([]);
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    }
  }

  return (
    <>
      <Modal as={Modal.Dialog} centered show={showModal} onHide={() => handleClose()}>
        <Modal.Header>
          <Modal.Title className="h6">{edit ? <FormattedMessage id="EDIT_BOOKING"/> : <FormattedMessage id="NEW_BOOKING"/>}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => handleClose()}/>
        </Modal.Header>
        <Modal.Body>
          <Form id="add-booking-form" onSubmit={handleAddBooking}>
            <Tab.Container defaultActiveKey="journey">
              <Nav fill variant="pills" className="flex-column flex-sm-row" onSelect={handleNavSelect}>
                <Nav.Item style={{marginLeft: '10px'}}>
                  <Nav.Link eventKey="journey" className="mb-sm-3 mb-md-0">
                    <FormattedMessage id="JOURNEY"/>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item style={{marginRight: '10px'}}>
                  <Nav.Link eventKey="customer" className="mb-sm-3 mb-md-0">
                    <FormattedMessage id="CUSTOMER"/>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="journey" className="py-4">
                  <Row>
                    <Form.Group>
                      <Form.Label><FormattedMessage id="STARTING_ADDRESS"/></Form.Label>
                      <Select ref={selectRef}
                              inputValue={inputValue}
                              options={addressOptions}
                              openMenuOnFocus={true}
                              isLoading={isGeoLoading}
                              loadingMessage={() => intl.formatMessage({id: 'LOADING'})}
                              onInputChange={(value) => handleInputChangeAddress(selectRef, value, false)}
                              onChange={(value) => handleSelectChange('startingAddress', value)}
                              placeholder={intl.formatMessage({id: 'SEARCH_AN_ADDRESS'})}
                              noOptionsMessage={() => intl.formatMessage({id: 'NO_ADDRESS_FOUND'})}
                              filterOption={() => true}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#61DAFB',
                                  primary: '#61DAFB',
                                }
                              })} styles={Utils.getSearchableSelectStyle()}/>
                    </Form.Group>
                  </Row>
                  <br/>
                  <Row>
                    <Form.Group>
                      <Form.Label><FormattedMessage id="ARRIVAL_ADDRESS"/></Form.Label>
                      <Select ref={selectRefReturn}
                              inputValue={inputValueReturn}
                              options={addressOptions}
                              openMenuOnFocus={true}
                              isLoading={isGeoLoading}
                              loadingMessage={() => intl.formatMessage({id: 'LOADING'})}
                              onInputChange={(value) => handleInputChangeAddress(selectRefReturn, value, true)}
                              onChange={(value) => handleSelectChange('arrivalAddress', value)}
                              placeholder={intl.formatMessage({id: 'SEARCH_AN_ADDRESS'})}
                              noOptionsMessage={() => intl.formatMessage({id: 'NO_ADDRESS_FOUND'})}
                              filterOption={() => true}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#61DAFB',
                                  primary: '#61DAFB',
                                }
                              })} styles={Utils.getSearchableSelectStyle()}/>
                    </Form.Group>
                  </Row>
                  <br/>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label><FormattedMessage id="VEHICLE"/></Form.Label>
                        <Form.Select id="vehicleId" onChange={handleChange} value={formData.vehicleId}>
                          {vehicles.map(v => <option key={`vehicle-${v.id}`} value={v.id}>{v.name}</option>)}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={3} className="mb-3">
                      <Form.Group>
                        <Form.Label><FormattedMessage id="PASSENGERS"/></Form.Label>
                        <Form.Control required type="number" id="passengers" defaultValue="1" min="1"
                                      onChange={handleChange} value={formData.passengers}/>
                      </Form.Group>
                    </Col>
                    <Col md={3} className="mb-3">
                      <Form.Group>
                        <Form.Label><FormattedMessage id="BAGGAGE"/></Form.Label>
                        <Form.Control required type="number" id="baggage" defaultValue="0" min="0"
                                      onChange={handleChange} value={formData.baggage}/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label><FormattedMessage id="JOURNEY"/></Form.Label>
                    <ButtonGroup>
                      <Button id="oneWay" onClick={handleClickJourney} variant={formData.route === 'ONE_WAY' ? 'secondary' : 'outline-primary'}
                              style={{borderColor: DAYS_BORDER_COLOR}}
                              size="sm"><FormattedMessage id="ONE_WAY"/></Button>
                      <Button id="roundTrip" onClick={handleClickJourney} variant={formData.route === 'ROUND_TRIP' ? 'secondary' : 'outline-primary'}
                              style={{borderColor: DAYS_BORDER_COLOR}}
                              size="sm"><FormattedMessage id="ROUND_TRIP"/></Button>
                    </ButtonGroup>
                  </Row>
                  <br/>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>{intl.formatMessage({id: formData.route === 'ROUND_TRIP' ? 'ONE_WAY_DATES_AND_TIMES' : 'DATES_AND_TIMES'})}</Form.Label>
                        <Datetime
                          locale={Utils.getLanguage()}
                          timeFormat={true}
                          closeOnSelect={true}
                          onChange={handleChangePickup}
                          renderInput={(props, openCalendar) => (
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faCalendarAlt}/>
                              </InputGroup.Text>
                              <Form.Control
                                required
                                type="text"
                                value={formData.pickUpTime ? moment(formData.pickUpTime).format(getLocaleDateFormat()) : ''}
                                onFocus={openCalendar}/>
                            </InputGroup>
                          )}/>
                      </Form.Group>
                    </Col>
                    {formData.route === 'ROUND_TRIP' && <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label><FormattedMessage id="RETURN_DATES_AND_TIMES"/></Form.Label>
                        <Datetime
                          locale={Utils.getLanguage()}
                          timeFormat={true}
                          closeOnSelect={true}
                          onChange={handleChangeReturnPickup}
                          renderInput={(props, openCalendar) => (
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faCalendarAlt}/>
                              </InputGroup.Text>
                              <Form.Control
                                required={formData.route === 'ROUND_TRIP'}
                                type="text"
                                value={formData.returnPickUpTime ? moment(formData.returnPickUpTime).format(getLocaleDateFormat()) : ''}
                                onFocus={openCalendar}/>
                            </InputGroup>
                          )}/>
                      </Form.Group>
                    </Col>}
                  </Row>
                  <Row>
                    <Col md={4} className="mb-3">
                      <Form.Group>
                        <Form.Label><FormattedMessage id="TRAVEL_TIME"/> (<FormattedMessage id="MINUTES"/>)</Form.Label>
                        <Form.Control required type="number" id="duration" min="0"
                                      onChange={handleChange} value={formData.duration}/>
                      </Form.Group>
                    </Col>
                    <Col md={4} className="mb-3">
                      <Form.Group>
                        <Form.Label><br/><FormattedMessage id="DISTANCE"/> ({lengthUnit})</Form.Label>
                        <Form.Control required type="number" id="distance" step="any" min="0"
                                      onChange={handleChange} value={formData.distance}/>
                      </Form.Group>
                    </Col>
                    <Col md={4} className="mb-3">
                      <Form.Group>
                        <Form.Label><br/><FormattedMessage id="PRICE"/></Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={Utils.getCurrencyIcon(currency?.code)}/>
                          </InputGroup.Text>
                          <Form.Control required type="number" id="price" step="any" min="0"
                                        onChange={handleChange} value={formData.price}/>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Group key="notes" className="mb-2">
                        <label><FormattedMessage id="ORDER_NOTES"/></label>
                        <Form.Control as="textarea" rows="3" id="notes" placeholder={intl.formatMessage({id: 'PLACEHOLDER_ORDER_NOTES'})} maxLength="1000"
                                      onChange={handleChange} value={formData.notes}/>
                      </Form.Group>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="customer" className="py-4">
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label><FormattedMessage id="FIRST_NAME"/></Form.Label>
                        <CreatableSelect ref={selectRefFirstName}
                                         value={customersOptions.find(option => option.value === formData.firstName) || null}
                                         inputValue={formData.firstName || ''}
                                         options={customersOptions}
                                         openMenuOnFocus={true}
                                         onInputChange={(value, actionMeta) => handleInputChangeName('firstName', value, actionMeta)}
                                         onChange={(value) => handleSelectCustomerChange(selectRefFirstName, value)}
                                         onMenuClose={() => {
                                           if (!isSelectingName) {
                                             const firstName = formData.firstName;
                                             setTimeout(() => {
                                               setFormData({
                                                 firstName
                                               })
                                             }, 0)
                                           }
                                         }}
                                         placeholder={intl.formatMessage({id: 'FIRST_NAME'})}
                                         formatCreateLabel={(inputValue) => intl.formatMessage({id: 'NEW_CUSTOMER'}) + ': ' + inputValue}
                                         theme={theme => ({
                                           ...theme,
                                           borderRadius: 0,
                                           colors: {
                                             ...theme.colors,
                                             primary25: '#61DAFB',
                                             primary: '#61DAFB',
                                           }
                                         })} styles={Utils.getSearchableSelectStyle()}/>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label><FormattedMessage id="LAST_NAME"/></Form.Label>
                        <CreatableSelect ref={selectRefLastName}
                                         value={customersOptions.find(option => option.value === formData.lastName) || null}
                                         inputValue={formData.lastName || ''}
                                         options={customersOptions}
                                         openMenuOnFocus={true}
                                         onInputChange={(value, actionMeta) => handleInputChangeName('lastName', value, actionMeta)}
                                         onChange={(value) => handleSelectCustomerChange(selectRefLastName, value)}
                                         onMenuClose={() => {
                                           if (!isSelectingName) {
                                             const lastName = formData.lastName;
                                             setTimeout(() => {
                                               setFormData({
                                                 lastName
                                               })
                                             }, 0)
                                           }
                                         }}
                                         placeholder={intl.formatMessage({id: 'LAST_NAME'})}
                                         formatCreateLabel={(inputValue) => intl.formatMessage({id: 'NEW_CUSTOMER'}) + ': ' + inputValue}
                                         theme={theme => ({
                                           ...theme,
                                           borderRadius: 0,
                                           colors: {
                                             ...theme.colors,
                                             primary25: '#61DAFB',
                                             primary: '#61DAFB',
                                           }
                                         })} styles={Utils.getSearchableSelectStyle()}/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mb-3">
                      <Form.Group>
                        <label><FormattedMessage id="COMPANY_NAME"/></label>
                        <Form.Control id="company" type="text" onChange={handleChange} value={formData.companyName}/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mb-3">
                      <Form.Group>
                        <label><FormattedMessage id="EMAIL_ADDRESS"/></label>
                        <Form.Control id="email" type="email" onChange={handleChange} value={formData.email}/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mb-3">
                      <Form.Group>
                        <label><FormattedMessage id="PHONE"/></label>
                        <Form.Control id="phone" type="tel" onChange={handleChange} value={formData.phone}/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mb-3">
                      <Form.Group>
                        <Form.Label>{intl.formatMessage({id: 'COUNTRY'})}</Form.Label>
                        <Countries id="country" onChange={handleChange} value={formData.country} intl={intl}/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mb-3">
                      <Form.Group>
                        <label><FormattedMessage id="STREET_ADDRESS"/></label>
                        <Form.Control id="address" type="text" onChange={handleChange} value={formData.address}/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mb-3">
                      <Form.Group>
                        <label><FormattedMessage id="APARTMENT_OFFICE_ETC"/></label>
                        <Form.Control id="additionalAddress" type="text" onChange={handleChange} value={formData.additionalAddress}/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <label><FormattedMessage id="CITY"/></label>
                        <Form.Control id="city" type="text" onChange={handleChange} value={formData.city}/>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <label><FormattedMessage id="ZIP_CODE"/></label>
                        <Form.Control id="postalCode" type="text" onChange={handleChange} value={formData.postalCode} maxlength="10"/>
                      </Form.Group>
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit" form="add-booking-form">
            {edit ? <FormattedMessage id="RECORD"/> : <FormattedMessage id="ADD"/>}
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={() => handleClose()}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}