import React, {useEffect} from 'react';
import {Button, Form, Modal} from '@themesberg/react-bootstrap';
import {FormattedMessage} from 'react-intl';
import API from '../services/API';
import Utils from '../services/Utils';
import {useHistory} from 'react-router-dom';

export default (props) => {
  const {intl, bookingId, show, onClose, onRefresh} = props;
  const history = useHistory();
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [cancelMessage, setCancelMessage] = React.useState('');

  useEffect(() => {
    setShowCancelModal(show);
  }, [show]);

  const handleClose = () => {
    setCancelMessage('');
    if (onClose) {
      onClose();
    }
    setShowCancelModal(false);
  };

  const handleCancelBooking = () => {
    API.postCancelAdminBooking(history, bookingId, cancelMessage ? cancelMessage : ' ').then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'BOOKING_CANCELED'}), intl.formatMessage({id: 'CUSTOMER_NOTIFIED_BY_EMAIL'}));
        handleClose();
        onRefresh();
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  return (
    <Modal as={Modal.Dialog} centered show={showCancelModal} onHide={() => handleClose()}>
      <Modal.Header>
        <Modal.Title className="h6"><FormattedMessage id="CANCEL_BOOKING"/></Modal.Title>
        <Button variant="close" aria-label="Close" onClick={() => handleClose()}/>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage id="YOU_CAN_ATTACH_MSG_TO_CUSTOMER"/>
        <br/>
        <br/>
        <Form.Control as="textarea" rows="3" id="cancelMessage"
                      onChange={(e) => setCancelMessage(e.target.value)} value={cancelMessage}/>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={handleCancelBooking}>
          <FormattedMessage id="CANCEL_BOOKING"/>
        </Button>
        <Button variant="link" className="text-gray ms-auto" onClick={() => handleClose()}>
          <FormattedMessage id="CLOSE"/>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}