import React, {useEffect} from 'react';
import {Button, ButtonGroup, Card, Col, Dropdown, Modal, Pagination, Row, Table} from '@themesberg/react-bootstrap';
import Utils from '../services/Utils';
import {useHistory} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormattedMessage} from 'react-intl';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCheck,
  faChevronDown,
  faCircleXmark,
  faClipboard,
  faEllipsisH,
  faEye,
  faFileCsv,
  faPlus,
  faShare,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import API from '../services/API';
import BookingModal from '../components/BookingModal';
import BookingConfirmModal from '../components/BookingConfirmModal';
import BookingShareModal from '../components/BookingShareModal';
import BookingCancelModal from '../components/BookingCancelModal';
import BookingCreateModal from '../components/BookingCreateModal';

export default (props) => {
  Utils.setBackground(false);

  const {intl} = props;
  const history = useHistory();
  const [bookings, setBookings] = React.useState([]);
  const [activeItem, setActiveItem] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [showShareModal, setShowShareModal] = React.useState(false);
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [currentBookingId, setCurrentBookingId] = React.useState(0);
  const [lengthUnit, setLengthUnit] = React.useState('KM');

  const handleConfirmBookingButton = (id) => {
    setCurrentBookingId(id);
    setShowConfirmModal(true);
  }

  const handleCancelBookingButton = (id) => {
    setCurrentBookingId(id);
    setShowCancelModal(true);
  }

  const handleDeleteBookingButton = (id) => {
    setCurrentBookingId(id);
    setShowDeleteModal(true);
  }

  const handleDetailsBookingButton = (id) => {
    setCurrentBookingId(id);
    setShowDetailsModal(true);
  }

  const handleShareBookingButton = (id) => {
    setCurrentBookingId(id);
    setShowShareModal(true);
  }

  const handleDeleteBooking = () => {
    API.deleteAdminBooking(history, currentBookingId).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'BOOKING_DELETED'}));
        setShowDeleteModal(false);
        refreshBookings(activeItem);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleCsvReport = () => {
    API.getBookingCsvReport(history).then(response => {
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Bookings.csv');
        document.body.appendChild(link);
        link.click();
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const TableRow = (props) => {
    const {customer, id, price, pickUpTime, returnPickUpTime, vehicle, status, payment, paymentMode, orderDate, currency, discount} = props;
    const statusVariant = status === 'CONFIRMED' ? 'success'
      : status === 'VALIDATED' ? 'warning'
        : status === 'EXPIRED' ? 'danger'
          : '';
    const paymentVariant = payment === 'PAID' ? 'success'
      : payment === 'FAILED' ? 'danger'
        : '';
    const statusText = status === 'CONFIRMED' ? intl.formatMessage({id: 'CONFIRMED'})
      : status === 'CANCELED' ? intl.formatMessage({id: 'CANCELED'})
        : status === 'VALIDATED' ? intl.formatMessage({id: 'WAITING_CONFIRMATION'})
          : status === 'EXPIRED' ? intl.formatMessage({id: 'EXPIRED'})
            : '';
    const paymentText = payment === 'PAID' ? intl.formatMessage({id: 'PAID'})
      : payment === 'FAILED' ? intl.formatMessage({id: 'PAYMENT_FAILED'})
        : payment === 'PENDING' ? intl.formatMessage({id: 'ONLINE_PAYMENT_IN_PROGRESS'})
          : '';

    return (
      <tr>
        <td className="td-center">{id}</td>
        <td className="fw-bold td-center">
          {customer.firstName ?? ''} {customer.lastName ?? ''}
        </td>
        <td className="td-center">{vehicle}</td>
        <td className="td-center">{pickUpTime}{returnPickUpTime && (<span><br/>{returnPickUpTime}</span>)}</td>
        <td className="td-center">{new Intl.NumberFormat(navigator.language, {style: 'currency', currency: currency}).format(discount ? discount.discountPrice : price)}</td>
        <td className="td-center"><span className={`text-${statusVariant}`}>{statusText}</span></td>
        <td className="td-center">
          {paymentMode === 'ONLINE' && <span className={`text-${paymentVariant}`}>{paymentText}</span>}
          {paymentMode === 'DRIVER' && <FormattedMessage id="DRIVER_PAYMENT"/>}
        </td>
        <td className="td-center">{orderDate}</td>
        <td className="td-center">
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark"/>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="custom-action-dropdown">
              <Dropdown.Item onSelect={() => handleDetailsBookingButton(id)}>
                <FontAwesomeIcon icon={faEye} className="me-2"/> <FormattedMessage id="VIEW_DETAILS"/>
              </Dropdown.Item>
              {status === 'VALIDATED' &&
                <Dropdown.Item className="text-success" onSelect={() => handleConfirmBookingButton(id)}>
                  <FontAwesomeIcon icon={faCheck} className="me-2"/> <FormattedMessage id="CONFIRM"/>
                </Dropdown.Item>
              }
              {status === 'VALIDATED' &&
                <Dropdown.Item className="text-warning" onSelect={() => handleCancelBookingButton(id)}>
                  <FontAwesomeIcon icon={faCircleXmark} className="me-2"/> <FormattedMessage id="CANCEL"/>
                </Dropdown.Item>
              }
              <Dropdown.Item className="text-info" onSelect={() => handleShareBookingButton(id)}>
                <FontAwesomeIcon icon={faShare} className="me-2"/> <FormattedMessage id="SHARE"/>
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onSelect={() => handleDeleteBookingButton(id)}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2"/> <FormattedMessage id="DELETE"/>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  const onPrevItem = () => {
    const prevActiveItem = activeItem === 1 ? activeItem : activeItem - 1;
    if (activeItem !== prevActiveItem) {
      setActiveItem(prevActiveItem);
      refreshBookings(prevActiveItem);
    }
  };

  const onNextItem = (totalPages) => {
    const nextActiveItem = activeItem === totalPages ? activeItem : activeItem + 1;
    if (activeItem !== nextActiveItem) {
      setActiveItem(nextActiveItem);
      refreshBookings(nextActiveItem);
    }
  };

  const items = [];
  let previousDots = false;
  let nextDots = false;
  for (let number = 1; number <= totalPages; number++) {
    const isItemActive = activeItem === number;

    const handlePaginationChange = () => {
      setActiveItem(number);
      refreshBookings(number);
    };

    if (totalPages <= 5) {
      items.push(
        <Pagination.Item active={isItemActive} key={number} onClick={handlePaginationChange}>
          {number}
        </Pagination.Item>
      );
    } else {
      if (isItemActive || ((activeItem + 3) >= number && number > activeItem - 3) || ((activeItem - 3) <= number && number < activeItem + 3)) {
        items.push(
          <Pagination.Item active={isItemActive} key={number} onClick={handlePaginationChange}>
            {number}
          </Pagination.Item>
        );
      } else if (!previousDots && number < activeItem) {
        previousDots = true;
        items.push(
          <Pagination.Item active={isItemActive} key="previousDots">
            ...
          </Pagination.Item>
        );
      } else if (!nextDots && number > activeItem) {
        nextDots = true;
        items.push(
          <Pagination.Item active={isItemActive} key="nextDots">
            ...
          </Pagination.Item>
        );
      }
    }
  }

  const refreshBookings = (page) => {
    API.getAdminBookings(history, page).then(response => {
      if (response && response.data.success === true) {
        setBookings(response.data.data.bookings);
        setTotalPages(response.data.data.pagination.totalPages)
        setLengthUnit(response.data.data.lengthUnit === 'MILE' ? 'MI' : 'KM');
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  useEffect(() => {
    if (!Utils.isAccessToken(history)) {
      return;
    }
    refreshBookings(1);
  }, []);

  return (
    <div>
      <br/>
      <Button variant="secondary" className="text-dark me-2" onClick={() => setShowCreateModal(true)}>
        <FontAwesomeIcon icon={faPlus} className="me-2"/>
        <span><FormattedMessage id="NEW_BOOKING"/></span></Button>
      <br/><br/>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pb-0" style={{minHeight: '400px'}}>
          <Row>
            <Col sm={6}>
              <h4 className="mt-2"><FormattedMessage id="BOOKINGS"/></h4>
            </Col>
            <Col sm={6}>
              <div className="d-flex justify-content-end mb-3">
                <Dropdown>
                  <Dropdown.Toggle as={Button} variant="primary">
                    <FontAwesomeIcon icon={faClipboard} className="me-2"/> <FormattedMessage id="REPORTS"/>
                    <span className="icon icon-small ms-1"><FontAwesomeIcon icon={faChevronDown}/></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dashboard-dropdown custom-export-dropdown mt-1">
                    <Dropdown.Item onSelect={handleCsvReport}>
                      <FontAwesomeIcon icon={faFileCsv} className="text-success me-2"/> CSV
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
          </Row>
          <Table hover className="user-table align-items-center">
            <thead className="thead-light">
            <tr>
              <th className="border-0 text-center">#</th>
              <th className="border-0 text-center"><FormattedMessage id="CUSTOMER"/></th>
              <th className="border-0 text-center"><FormattedMessage id="VEHICLE"/></th>
              <th className="border-0 text-center"><FormattedMessage id="PICKUP_DATETIME"/></th>
              <th className="border-0 text-center"><FormattedMessage id="AMOUNT"/></th>
              <th className="border-0 text-center"><FormattedMessage id="STATUS"/></th>
              <th className="border-0 text-center"><FormattedMessage id="PAYMENT"/></th>
              <th className="border-0 text-center"><FormattedMessage id="ORDER_DATE"/></th>
              <th className="border-0 text-center"><FormattedMessage id="ACTIONS"/></th>
            </tr>
            </thead>
            <tbody>
            {bookings && bookings.map(b => <TableRow key={`booking-${b.id}`} {...b} />)}
            </tbody>
          </Table>
          <Pagination size="md" className="mt-3" style={{float: 'right'}}>
            <Pagination.Prev onClick={onPrevItem}>
              <FontAwesomeIcon icon={faAngleDoubleLeft}/>
            </Pagination.Prev>
            {items}
            <Pagination.Next onClick={() => onNextItem(totalPages)}>
              <FontAwesomeIcon icon={faAngleDoubleRight}/>
            </Pagination.Next>
          </Pagination>
        </Card.Body>
        <Modal as={Modal.Dialog} centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header>
            <Modal.Title className="h6"><FormattedMessage id="DELETE_BOOKING"/></Modal.Title>
            <Button variant="close" aria-label="Close" onClick={() => setShowDeleteModal(false)}/>
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage id="NO_LONGER_SEE_THIS_BOOKING"/>
            <br/>
            <FormattedMessage id="CUSTOMER_NOT_NOTIFIED_BY_EMAIL"/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleDeleteBooking}>
              <FormattedMessage id="DELETE"/>
            </Button>
            <Button variant="link" className="text-gray ms-auto" onClick={() => setShowDeleteModal(false)}>
              <FormattedMessage id="CLOSE"/>
            </Button>
          </Modal.Footer>
        </Modal>
        {currentBookingId != null &&
          <BookingModal intl={intl}
                        lengthUnit={lengthUnit}
                        booking={bookings.find(b => b.id === currentBookingId)}
                        show={showDetailsModal}
                        onClose={() => setShowDetailsModal(false)}
                        onRefresh={() => refreshBookings(activeItem)}>
          </BookingModal>
        }
        <BookingConfirmModal intl={intl} bookingId={currentBookingId} show={showConfirmModal} onClose={() => setShowConfirmModal(false)} onRefresh={() => refreshBookings(activeItem)}></BookingConfirmModal>
        <BookingCancelModal intl={intl} bookingId={currentBookingId} show={showCancelModal} onClose={() => setShowCancelModal(false)} onRefresh={() => refreshBookings(activeItem)}></BookingCancelModal>
        <BookingShareModal intl={intl} bookingId={currentBookingId} show={showShareModal} onClose={() => setShowShareModal(false)}></BookingShareModal>
        <BookingCreateModal intl={intl} lengthUnit={lengthUnit} show={showCreateModal} onClose={() => setShowCreateModal(false)} onRefresh={() => refreshBookings(activeItem)}></BookingCreateModal>
      </Card>
    </div>
  );
};
