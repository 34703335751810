import React, {useEffect} from 'react';
import {Button, Card} from '@themesberg/react-bootstrap';
import Utils from '../services/Utils';
import {useHistory} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import enLocale from '@fullcalendar/core/locales/en-gb';
import esLocale from '@fullcalendar/core/locales/es';
import API from '../services/API';
import {faClock, faUser} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight, faCar, faPeopleGroup, faPlus} from '@fortawesome/free-solid-svg-icons';
import BookingModal from '../components/BookingModal';
import BookingCreateModal from '../components/BookingCreateModal';

export default (props) => {
  Utils.setBackground(false);

  const {intl} = props;
  const history = useHistory();
  const [booking, setBooking] = React.useState();
  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const calendarRef = React.useRef();
  const currentDate = new Date().toISOString().split('T')[0];
  const [lengthUnit, setLengthUnit] = React.useState('KM');

  useEffect(() => {
    if (!Utils.isAccessToken(history)) {
      return;
    }
    refreshPlanning();
  }, []);

  const refreshPlanning = () => {
    setBooking(undefined);
    API.getPlanning(history).then(response => {
      if (response && response.data.success === true) {
        const events = [];
        response.data.data?.events?.forEach(event => {
          events.push({
            id: event.id,
            title: '',
            start: event.date,
            textColor: 'white',
            backgroundColor: getEventColor(event),
            borderColor: getEventColor(event),
            extendedProps: {
              event
            }
          });
        });
        setEvents(events);
        setLengthUnit(response.data.data.lengthUnit === 'MILE' ? 'MI' : 'KM');
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const getEventColor = (event) => {
    switch (event.status) {
      case 'CONFIRMED':
        return '#10B981';
      case 'VALIDATED':
        return '#F5B759';
      case 'EXPIRED':
        return '#E11D48';
      default:
        return '#3788D8';
    }
  }

  const getCalendarLocale = () => {
    switch (Utils.getLanguage().toUpperCase()) {
      case 'FR':
        return frLocale;
      case 'EN':
        return enLocale;
      case 'ES':
        return esLocale;
      default:
        return frLocale;
    }
  }

  const onEventClick = (props) => {
    const {event: {id}} = props;

    if (Number(booking?.id) === Number(id)) {
      setShowDetailsModal(true);
      return;
    }

    API.getAdminBooking(history, id).then(response => {
      if (response && response.data.success === true) {
        setBooking(response.data.data);
        setShowDetailsModal(true);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  };

  return (
    <div>
      <br/>
      <Button variant="secondary" className="text-dark me-2" onClick={() => setShowModal(true)}>
        <FontAwesomeIcon icon={faPlus} className="me-2"/>
        <span><FormattedMessage id="NEW_BOOKING"/></span></Button>
      <br/><br/>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pb-0" style={{minWidth: '600px'}}>
          <FullCalendar
            selectable
            events={events}
            ref={calendarRef}
            initialView="dayGridWeek"
            displayEventTime={false}
            initialDate={currentDate}
            eventClick={onEventClick}
            plugins={[dayGridPlugin, interactionPlugin]}
            locale={getCalendarLocale()}
            headerToolbar={{
              start: 'prev,next',
              center: 'title',
              end: 'dayGridYear,dayGridMonth,dayGridWeek'
            }}
            eventContent={(eventInfo) => {
              const {event} = eventInfo.event.extendedProps;
              return (
                <div style={{whiteSpace: 'normal'}}>
                  <div className="col-calendar-event">
                    <span>&nbsp;<FontAwesomeIcon icon={faClock} style={{marginRight: '5px'}}/>
                    <span style={{fontFamily: 'Arial, Helvetica Neue, Helvetica, sans-serif', fontWeight: 'bold'}}>{event.schedule}</span><br/></span>
                    <span><FontAwesomeIcon icon={faPeopleGroup} style={{marginRight: '2px'}}/>{event.passengers}&nbsp;</span>
                  </div>
                  {event.name && <span>&nbsp;<FontAwesomeIcon icon={faUser} style={{marginRight: '5px'}}/>{event.name}<br/></span>}
                  <div className="col-calendar-event">
                    <span>&nbsp;<FontAwesomeIcon icon={faCar} style={{marginRight: '5px'}}/>{event.vehicle}</span><br/>
                    {event.route && event.route === 'ROUND_TRIP' &&
                      <>
                        {event.isReturn === false && <span>&nbsp;<FontAwesomeIcon icon={faArrowRight} style={{marginRight: '2px'}}/>&nbsp;</span>}
                        {event.isReturn === true && <span>&nbsp;<FontAwesomeIcon icon={faArrowLeft} style={{marginRight: '2px'}}/>&nbsp;</span>}
                      </>
                    }
                  </div>
                </div>
              );
            }}
          />
        </Card.Body>
        {booking &&
          <BookingModal intl={intl} lengthUnit={lengthUnit} booking={booking} show={showDetailsModal} onClose={() => setShowDetailsModal(false)} onRefresh={() => refreshPlanning()}></BookingModal>
        }
      </Card>
      <BookingCreateModal intl={intl} lengthUnit={lengthUnit} show={showModal} onClose={() => setShowModal(false)} onRefresh={() => refreshPlanning()}></BookingCreateModal>
    </div>
  );
}