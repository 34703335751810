import React, {useEffect, useReducer} from 'react';
import {Button, Card, Col, Form, InputGroup, Modal, OverlayTrigger, Row, Table, Tooltip} from '@themesberg/react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faCheck, faPenToSquare, faPlus, faPowerOff, faTrashCan, faXmark} from '@fortawesome/free-solid-svg-icons';
import {FormattedMessage} from 'react-intl';
import Utils from '../services/Utils';
import {useHistory} from 'react-router-dom';
import API from '../services/API';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Error from '../services/Error';


export default (props) => {

  const formReducer = (state, event) => {
    if (event.value !== undefined) {
      return {
        ...state,
        [event.name]: event.value
      }
    } else {
      return event;
    }
  }

  const {intl} = props;
  const history = useHistory();
  const hover = ['hover'];
  const [showModal, setShowModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [editModalMode, setEditModalMode] = React.useState(false);
  const [currentVehicleId, setCurrentVehicleId] = React.useState(false);
  const [currentVehicleName, setCurrentVehicleName] = React.useState('');
  const [vehicles, setVehicles] = React.useState([]);
  const [formData, setFormData] = useReducer(formReducer, {});
  const [currency, setCurrency] = React.useState({code: 'EUR', symbol: '€'});
  const [formatter, setFormatter] = React.useState(new Intl.NumberFormat(navigator.language, {style: 'currency', currency: 'EUR'}));
  const [lengthUnit, setLengthUnit] = React.useState('KM');

  const refreshVehicles = () => {
    API.getVehicles(history).then(response => {
      if (response && response.data.success === true) {
        const vehicles = response.data.data.vehicles.sort(function (a, b) {
          return a.position - b.position
        });
        setVehicles(vehicles);
        setLengthUnit(response.data.data.lengthUnit === 'MILE' ? 'MI' : 'KM');
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  useEffect(() => {
    if (!Utils.isAccessToken(history)) {
      return;
    }
    refreshVehicles();
    API.getCurrency(history).then(response => {
      if (response && response.data.success === true) {
        setFormatter(new Intl.NumberFormat(navigator.language, {
          style: 'currency',
          currency: response.data.data.code,
        }));
        setCurrency(response.data.data);
      }
    });
  }, []);

  const handleAddOrEdit = (e) => {
    e.preventDefault();
    if (editModalMode) {
      API.putVehicles(history, formData, currentVehicleId).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SAVED_CHANGES'}));
          setShowModal(false);
          refreshVehicles();
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    } else {
      API.postVehicles(history, formData).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'VEHICLE_ADDED'}));
          setShowModal(false);
          refreshVehicles();
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    }
  }

  const handleChange = event => {
    setFormData({
      name: event.target.id,
      value: event.target.value
    });
  }

  const handleNewVehicleButton = () => {
    setFormData({});
    setEditModalMode(false);
    setShowModal(true);
  }

  const handleEditVehicleButton = (props) => {
    setFormData(props);
    setCurrentVehicleId(props.id);
    setEditModalMode(true);
    setShowModal(true);
  }

  const handleEnableOrDisableButton = (props) => {
    if (props.active) {
      API.getDisableVehicle(history, props.id).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'VEHICLE_DISABLED'}));
          refreshVehicles();
        } else if (response && response.data.success === false && response.data.data === Error.lastVehicleMustNotBeDisabled()) {
          Utils.notifyWarning(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'YOU_CANT_DISABLE_LAST_VEHICLE'}));
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    } else {
      API.getEnableVehicle(history, props.id).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'VEHICLE_ENABLED'}));
          setShowDeleteModal(false);
          refreshVehicles();
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    }
  }

  const handleDeleteVehicleButton = (props) => {
    setCurrentVehicleId(props.id);
    setCurrentVehicleName(props.name);
    setShowDeleteModal(true);
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // update position variable
    let index = 0;
    for (const [key, value] of Object.entries(result)) {
      value.position = index++;
    }

    return result;
  };

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      vehicles,
      result.source.index,
      result.destination.index
    );

    setVehicles(items);

    // Prepare data for backend
    let data = {
      vehicles: []
    };

    for (const [key, value] of Object.entries(items)) {
      data.vehicles.push({
        id: value.id,
        position: value.position
      });
    }

    API.postReorderVehicles(history, data).then(response => {
      if (response && response.data.success === true) {
        // Everything is OK
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const deleteVehicle = () => {
    API.deleteVehicles(history, currentVehicleId).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'VEHICLE_DELETED'}));
        setShowDeleteModal(false);
        refreshVehicles();
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const TableRow = (props) => {
    const {key, provided, name, passengersSpace, baggageSpace, numberOwned, priceFrom, euroPerKm, euroPerMinute, euroPerPassenger, minFare, active} = props;

    return (
      <tr key={key} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <td className="td-center">
          <FontAwesomeIcon icon={faBars}/>
        </td>
        <td className="td-center">
          {name}
        </td>
        <td className="td-center">
          {passengersSpace}
        </td>
        <td className="td-center">
          {baggageSpace}
        </td>
        <td className="td-center">
          {numberOwned}
        </td>
        <td className="td-center">
          {formatter.format(priceFrom)}
        </td>
        <td className="td-center">
          {formatter.format(euroPerKm)}
        </td>
        <td className="td-center">
          {formatter.format(euroPerMinute)}
        </td>
        <td className="td-center">
          {formatter.format(euroPerPassenger)}
        </td>
        <td className="td-center">
          {formatter.format(minFare)}
        </td>
        <td className="td-center">
          {active ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faXmark}/>}
        </td>
        <td className="td-center">
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip><FormattedMessage id="EDIT"/></Tooltip>
                          }>
            <Button style={{marginRight: '5px'}} variant="light" onClick={() => handleEditVehicleButton(props)}>
              <FontAwesomeIcon icon={faPenToSquare}/>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip>{active ? <FormattedMessage id="DISABLE"/> : <FormattedMessage id="ENABLE"/>}</Tooltip>
                          }>
            <Button style={{marginRight: '5px'}} variant="warning" onClick={() => handleEnableOrDisableButton(props)}>
              <FontAwesomeIcon icon={faPowerOff}/>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip><FormattedMessage id="DELETE"/></Tooltip>
                          }>
            <Button variant="danger" onClick={() => handleDeleteVehicleButton(props)}>
              <FontAwesomeIcon icon={faTrashCan}/>
            </Button>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <div>
      <br/>
      <Button variant="secondary" className="text-dark me-2" onClick={handleNewVehicleButton}>
        <FontAwesomeIcon icon={faPlus} className="me-2"/>
        <span><FormattedMessage id="NEW_VEHICLE"/></span></Button>
      <br/><br/>
      {(vehicles && vehicles.length > 0) &&
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table responsive className="table-centered table-nowrap rounded mb-0">
              <thead className="thead-light">
              <tr>
                <th className="border-0 text-center"></th>
                <th className="border-0 text-center"><FormattedMessage id="NAME"/></th>
                <th className="border-0 text-center"><FormattedMessage id="PASSENGERS_SPACE" values={{newLine: <br/>}}/></th>
                <th className="border-0 text-center"><FormattedMessage id="BAGGAGE_SPACE" values={{newLine: <br/>}}/></th>
                <th className="border-0 text-center"><FormattedMessage id="NUMBER_OWNED" values={{newLine: <br/>}}/></th>
                <th className="border-0 text-center"><FormattedMessage id="PRICE_FROM" values={{newLine: <br/>}}/></th>
                <th className="border-0 text-center">{currency.symbol} / {lengthUnit}</th>
                <th className="border-0 text-center">{currency.symbol} / <FormattedMessage id="MINUTE"/></th>
                <th className="border-0 text-center">{currency.symbol} / <FormattedMessage id="PASSENGER"/></th>
                <th className="border-0 text-center"><FormattedMessage id="MIN_COST"/></th>
                <th className="border-0 text-center"><FormattedMessage id="ACTIVE_TAB"/></th>
                <th className="border-0 text-center"><FormattedMessage id="ACTIONS"/></th>
              </tr>
              </thead>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="table-body">
                  {(provided, snapshot) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {vehicles && vehicles.map((data, index) => {
                        return (
                          <Draggable
                            draggableId={data.id.toString()}
                            key={data.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <TableRow key={`vehicle-${data.id}`} provided={provided} {...data} />
                            )}
                          </Draggable>
                        )
                      }
                    )}
                    {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </Card.Body>
        </Card>
      }
      <Modal as={Modal.Dialog} centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title className="h6">{editModalMode ? <FormattedMessage id="EDIT_VEHICLE"/> : <FormattedMessage id="NEW_VEHICLE"/>}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowModal(false)}/>
        </Modal.Header>
        <Modal.Body>
          <Form id="add-vehicle-form" onSubmit={handleAddOrEdit}>
            <Row>
              <Form.Group>
                <Form.Label><FormattedMessage id="NAME"/></Form.Label>
                <Form.Control required type="text" id="name"
                              onChange={handleChange} value={formData.name}/>
              </Form.Group>
            </Row>
            <br/>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="PASSENGERS_SPACE" values={{newLine: ' '}}/></Form.Label>
                  <Form.Control required type="number" id="passengersSpace"
                                onChange={handleChange} value={formData.passengersSpace}/>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="BAGGAGE_SPACE" values={{newLine: ' '}}/></Form.Label>
                  <Form.Control required type="number" id="baggageSpace"
                                onChange={handleChange} value={formData.baggageSpace}/>
                </Form.Group>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="NUMBER_OWNED" values={{newLine: ' '}}/></Form.Label>
                  <Form.Control required type="number" id="numberOwned"
                                onChange={handleChange} value={formData.numberOwned}/>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="PRICE_FROM" values={{newLine: ' '}}/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={Utils.getCurrencyIcon(currency.code)}/>
                    </InputGroup.Text>
                    <Form.Control type="number" id="priceFrom" step="any"
                                  onChange={handleChange} value={formData.priceFrom}/>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>{currency.symbol} / {lengthUnit}</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={Utils.getCurrencyIcon(currency.code)}/>
                    </InputGroup.Text>
                    <Form.Control type="number" id="euroPerKm" step="any"
                                  onChange={handleChange} value={formData.euroPerKm}/>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>{currency.symbol} / <FormattedMessage id="MINUTE"/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={Utils.getCurrencyIcon(currency.code)}/>
                    </InputGroup.Text>
                    <Form.Control type="number" id="euroPerMinute" step="any"
                                  onChange={handleChange} value={formData.euroPerMinute}/>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>{currency.symbol} / <FormattedMessage id="PASSENGER"/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={Utils.getCurrencyIcon(currency.code)}/>
                    </InputGroup.Text>
                    <Form.Control type="number" id="euroPerPassenger" step="any"
                                  onChange={handleChange} value={formData.euroPerPassenger}/>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="MIN_COST"/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={Utils.getCurrencyIcon(currency.code)}/>
                    </InputGroup.Text>
                    <Form.Control type="number" id="minFare" step="any"
                                  onChange={handleChange} value={formData.minFare}/>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit" form="add-vehicle-form">
            {editModalMode ? <FormattedMessage id="RECORD"/> : <FormattedMessage id="ADD"/>}
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setShowModal(false)}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal as={Modal.Dialog} centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header>
          <Modal.Title className="h6"><FormattedMessage id="DELETE_VEHICLE" values={{name: currentVehicleName}}/></Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowDeleteModal(false)}/>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="danger" onClick={deleteVehicle}>
            <FormattedMessage id="DELETE"/>
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setShowDeleteModal(false)}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
