import React, {useEffect, useReducer} from 'react';
import {Button, Form, InputGroup, Modal} from '@themesberg/react-bootstrap';
import {FormattedMessage} from 'react-intl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import API from '../services/API';
import Utils from '../services/Utils';
import {useHistory} from 'react-router-dom';

export default (props) => {

  const formReducer = (state, event) => {
    if (event.value !== undefined) {
      return {
        ...state,
        [event.name]: event.value
      }
    } else {
      return event;
    }
  }

  const {intl, bookingId, show, onClose} = props;
  const history = useHistory();
  const [showShareModal, setShowShareModal] = React.useState(false);
  const [shareFormData, setShareFormData] = useReducer(formReducer, {});

  useEffect(() => {
    setShowShareModal(show);
  }, [show]);

  const handleClose = () => {
    setShareFormData({});
    if (onClose) {
      onClose();
    }
    setShowShareModal(false);
  };

  const handleChange = event => {
    setShareFormData({
      name: event.target.id,
      value: event.target.value
    });
  }

  const handleShareBooking = (e) => {
    e.preventDefault();
    API.postShareAdminBooking(history, bookingId, shareFormData).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SENT_TO_YOUR_RECIPIENT'}));
        handleClose();
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  return (
    <Modal as={Modal.Dialog} centered show={showShareModal} onHide={() => handleClose()}>
      <Modal.Header>
        <Modal.Title className="h6"><FormattedMessage id="SHARE_WITH_COLLEAGUE"/></Modal.Title>
        <Button variant="close" aria-label="Close" onClick={() => handleClose()}/>
      </Modal.Header>
      <Modal.Body>
        <Form id="share-form" onSubmit={handleShareBooking}>
          <Form.Group id="email" className="mb-4">
            <Form.Label><FormattedMessage id="EMAIL_ADDRESS"/></Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faEnvelope}/>
              </InputGroup.Text>
              <Form.Control autoFocus required id="email"
                            type="email"
                            onChange={handleChange}
                            value={shareFormData.email}/>
            </InputGroup>
          </Form.Group>
          <FormattedMessage id="YOU_CAN_ATTACH_MSG_TO_RECIPIENT"/>
          <br/>
          <Form.Control as="textarea" rows="3" id="message"
                        onChange={handleChange} value={shareFormData.message}/>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" type="submit" form="share-form">
          <FormattedMessage id="SEND"/>
        </Button>
        <Button variant="link" className="text-gray ms-auto" onClick={() => handleClose()}>
          <FormattedMessage id="CLOSE"/>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}