//export const BASE_URL = 'http://localhost:3000/#';
//export const API_URL = 'http://localhost:8080';
export const WEBSITE = 'https://www.clickchauffeur.com';
export const ACCESS_TOKEN = 'accessToken';
export const EMAIL = 'email';
export const NAME = 'name';
export const PHONE = 'phone';
export const STRIPE_ACTIVE = 'stripeActive';
export const STRIPE_CB = 'stripeCB';
export const DAYS_BORDER_COLOR = '#262B40';
export const LANGUAGE = 'clickchauffeur-language';
export const TOMTOM = 'AifBJtaOHWigwqUFRdhRp37aXJPtLKEf';
export const MAPBOX = 'pk.eyJ1Ijoid2ViYXVkaXQiLCJhIjoiY2w0ZHd4dGg3MGNvcDNkam5lMGk4ZmR3ciJ9.25x8UpR7xO1NWgzdqbrGPg';

// PROD
export const BASE_URL = 'https://app.clickchauffeur.com/#';
export const API_URL = 'https://app.clickchauffeur.com:8443';
